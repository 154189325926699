<template>
    <div>
<!--        <el-drawer :visible.sync="showExampleDialog" :title="form.status==2?'素材管理【待审核】':'素材管理'" size="75%">-->
            <div style="padding: 0 10px" id="loading-wrap" class="example-dialog-wrap">
                <el-form :model="form" label-width="100px" size="small ">
                    <el-form-item label="素材编码">
                        <el-input v-model="form.materialCode" placeholder="输入素材编码"></el-input>
                    </el-form-item>
                    <el-form-item label="素材类型" v-if="isCheck==0">
                        <el-select v-model="form.materialType" placeholder="请选择素材类型">
                            <el-option label="文本" value="text"></el-option>
                            <el-option label="图片" value="image"></el-option>
                            <el-option label="视频" value="video"></el-option>
                            <el-option label="文件" value="file"></el-option>
                            <el-option label="语音消息" value="voice"></el-option>
                            <el-option label="小程序" value="miniprogram"></el-option>
                            <el-option label="图文" value="link"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="素材分类" v-if="isCheck==0">
                        <el-select v-model="form.materialClass" placeholder="请选择素材分类">
                            <el-option label="用户素材" :value="1"></el-option>
                            <el-option label="内部素材" :value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="前置素材" v-if="isCheck==0">
                        <el-select v-model="form.parentId" placeholder="请选择前置素材" clearable>
                            <el-option
                                    v-for="item in materialOption"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="已发布文案" v-if="form.materialType == 'text'">
                        <div style="display: flex">
                            <el-input type="textarea" :rows="6" v-model="form.pushText" placeholder="暂无"
                                      @blur="blurEvent" :disabled="true"></el-input>
                        </div>

                    </el-form-item>
                    <el-form-item label="待发布文案" v-if="form.materialType == 'text'">
                        <div>
                            <el-input type="textarea" :rows="6" v-model="form.pushTextPre" placeholder="输入推送文本"
                                      @blur="blurEvent"></el-input>
                            <div style="margin-left: 10px">
                                <p style="margin: 0">可选择变量作为参数:</p>
                                <el-tag style="margin: 0 10px 10px 0" v-for="item in tabsTemplateList" size="mini"
                                        type="success" @click="insertContentHandle(item.template,0)">{{item.name}}

                                </el-tag>
                            </div>
                        </div>
                    </el-form-item>

                    <el-form-item label="非标回复文本" v-if="form.materialType == 'text'">
                        <div>
                            <el-input type="textarea"
                                      :rows="6" v-model="form.keyword" placeholder="非标回复文本"
                                      @blur="blurEvent1"></el-input>
                            <div style="margin-left: 10px">
                                <p style="margin: 0">可选择变量作为参数:</p>
                                <el-tag style="margin: 0 10px 10px 0" v-for="item in tabsTemplateList" size="mini"
                                        type="success" @click="insertContentHandle(item.template,1)">{{item.name}}

                                </el-tag>
                            </div>
                        </div>
                    </el-form-item>

                    <el-form-item label="埋点Id/chan_id" v-if="isCheck==0 && form.materialType == 'text'">
                        <el-input v-model="form.chanId" placeholder="埋点Id" readonly="readonly"></el-input>
                        推送文本中的链接的chan_id,系统自动获取

                    </el-form-item>

                    <el-form-item label="选择文件"
                                  v-if="(form.materialType == 'link' ||form.materialType == 'miniprogram' ||form.materialType == 'image' || form.materialType == 'video' || form.materialType == 'voice' || form.materialType == 'file') && (form.materailOuterId == null || form.materailOuterId == undefined)">
                        <el-upload
                                class="upload-demo" :limit="1" :auto-upload="true"
                                :data="{'mediaType':form.materialType}"
                                :action="resourceUrl+'/lechun-cms/scrmMaterial/uploadMedia'"
                                :file-list="fileList" :on-success="uploadSuccess">
                            <el-button size="small" type="primary">点击上传</el-button>
                            <div slot="tip"
                                 v-if="form.materialType == 'image'||form.materialType == 'link'||form.materialType == 'miniprogram'"
                                 class="el-upload__tip">10MB，支持JPG,PNG格式

                            </div>
                            <div slot="tip" v-if="form.materialType == 'video'" class="el-upload__tip">10MB，支持MP4格式

                            </div>
                            <div slot="tip" v-if="form.materialType == 'voice'" class="el-upload__tip">
                                2MB，播放长度不超过60s，仅支持AMR格式

                            </div>
                            <div slot="tip" v-if="form.materialType == 'file'" class="el-upload__tip">20MB</div>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="OSS-URL" v-if="form.ossUrl != null && form.ossUrl != undefined">
                        <p>{{form.ossUrl}}</p>
                        <img :src="form.ossUrl" width="80" height="80" class="head_pic"/>
                    </el-form-item>
                    <el-form-item label="企微临时素材ID"
                                  v-if="form.materailOuterId != null && form.materailOuterId != undefined">
                        <p>{{form.materailOuterId}}</p>
                    </el-form-item>
                    <!--<el-form-item label="上传时间"-->
                    <!--v-if="form.createTime != null && form.createTime != undefined && form.materialType != 'text'">-->
                    <!--<p>{{form.createTime}}</p>-->
                    <!--</el-form-item>-->
                    <el-form-item label="失效时间" v-if="form.disableTime != null && form.disableTime != undefined">
                        <p>{{form.disableTime}}</p>
                    </el-form-item>
                    <el-form-item label="标题" v-if="form.materialType == 'link'||form.materialType == 'miniprogram'">
                        <el-input v-model="form.title" placeholder="标题"></el-input>
                    </el-form-item>
                    <el-form-item label="图片连接" v-if="form.materialType == 'link'">
                        <p>{{form.linkImglUrl}}</p>
                    </el-form-item>
                    <!--描述-->
                    <el-form-item label="H5 摘要" v-if="form.materialType == 'link'">
                        <el-input v-model="form.linkDesc" placeholder="图文描述"></el-input>
                    </el-form-item>
                    <!--跳转连接-->
                    <el-form-item label="H5连接" v-if="form.materialType == 'link'">
                        <el-input v-model="form.link" placeholder="跳转连接 以http开头"></el-input>
                    </el-form-item>
                    <!--小程序-->
                    <el-form-item label="页面路径" v-if="form.materialType == 'miniprogram'">
                        <el-input v-model="form.page" placeholder="小程序路径"></el-input>
                    </el-form-item>
                    <el-form-item label="appId" v-show="false">
                        <el-input v-model="form.appid" placeholder="appId"></el-input>
                    </el-form-item>
                    <el-form-item label="miniImgUrl" v-show="false">
                        <p>{{form.miniImgUrl}}</p>
                    </el-form-item>
                    <el-form-item label="加入目录">
                        <el-select v-model="form.groupId">
                            <el-option v-for="item in treeData" :label="item.groupName" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="排序">
                        <el-input-number v-model="form.seq" :min="0"></el-input-number>
                    </el-form-item>
                    <el-form-item label="非工作日是否流转">
                        <el-select size="small" v-model="form.isTransfer" placeholder="请选择">
                            <el-option :value="1" label="是"></el-option>
                            <el-option :value="0" label="否"></el-option>
                        </el-select>
                        <span><i class="el-icon-warning" style="margin-left:10px;color: #E6A23C"></i> 标示非工作时间文案是否流转到下个工作日</span>
                    </el-form-item>
                    <el-form-item label="推送类型">
                        <el-select size="small" v-model="form.pushType" placeholder="请选择">
                            <el-option v-for="item in pushTypeList"
                                       :key="item.id"
                                       :label="item.name"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                        <span><i class="el-icon-warning" style="margin-left:10px;color: #E6A23C"></i>自动推送将由企微确认后推送,手动推送 由企微发送 </span>
                    </el-form-item>
                    <el-form-item label="渠道活码">
                        <el-select size="small" clearable multiple v-model="channelItem" clearable
                                   placeholder="请选择">
                            <el-option v-for="item in channelList"
                                       :key="item.id"
                                       :label="item.name"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                        <span><i class="el-icon-warning"
                                 style="margin-left:10px;color: #E6A23C"></i>活码推送欢迎语 </span>
                    </el-form-item>
                    <el-form-item label="推送优先级">
                        <el-select size="small" clearable v-model="form.priorityLevel" placeholder="请选择">
                            <el-option v-for="item in priorityLevelList"
                                       :key="item.id"
                                       :label="item.name"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                        <span><i class="el-icon-warning"
                                 style="margin-left:10px;color: #E6A23C"></i>优先级高的优先处理 </span>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="saveMaterial(1)">{{isCheck == 1 ? "记录LOG审核" : "保存"}}
                        </el-button>
                        <el-button type="primary" @click="saveMaterial(0)" v-if="isCheck==1">
                            {{isCheck == 1 ? "不记录LOG审核" : "保存"}}
                        </el-button>
                        <template v-if="isCheck == 1">
                            <el-popover
                                placement="top"
                                title="审核不通过原因"
                                width="500"
                                v-model="checkBackVisible">
                                <el-form label-width="70px" size="small ">
                                    <el-form-item label="原因">
                                        <el-input type="textarea" :rows="5" v-model="form.checkContent" placeholder="请输入审核不通过原因"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="danger" size="mini" @click="saveMaterial(0,2)">确 定 驳 回</el-button>
                                        <el-button type="info" size="mini" @click="checkBackVisible = false">取消</el-button>
                                    </el-form-item>
                                </el-form>
                                <el-button style="margin-left: 20px" slot="reference" type="warning">驳回</el-button>

                            </el-popover>
                        </template>

                    </el-form-item>
                </el-form>
                日志记录
                <el-table :data="tableList" border size="mini">
                    <el-table-column type="index" label="序号"></el-table-column>
                    <el-table-column prop="materialCode" label="素材编码" width="100px">
                        <template slot-scope="scope">
                            <div style="font-size: 10px;">{{scope.row.materialCode}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="pushText" label="文案">
                        <template slot-scope="scope">
                            <div style="font-size: 10px;"><p class="copy-text" slot="reference"
                                                             v-html="scope.row.pushText"></p></div>
                        </template>
                    </el-table-column>

                    <el-table-column prop="beginTime" label="启用时间段" width="150px">
                        <template slot-scope="scope">
                            <div style="font-size: 10px;">开始：{{scope.row.beginTime}}</div>
                            <div style="font-size: 10px;">结束：{{scope.row.endTime}}</div>
                        </template>
                    </el-table-column>

                    <el-table-column prop="materialType" label="发送次数" width="130px">
                        <template slot-scope="scope">
                            <div style="font-size: 10px;">
                                <i style="color: #55a532" class="el-icon-s-order"></i>总数：{{scope.row.sendCount}}

                            </div>
                            <div style="font-size: 10px">
                                <i style="color: #55a532" class="el-icon-s-order"></i>实发：{{scope.row.factCount}}

                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="materialType" label="执行率" width="140px">
                        <template slot-scope="scope">
                            <div style="font-size: 10px;">
                                <i style="color: #55a532" class="el-icon-s-order"></i>执行率：{{scope.row.execRate}}

                            </div>
                            <div style="font-size: 10px;">
                                <i style="color: #55a532" class="el-icon-s-order"></i>回复率：{{scope.row.repateRate}}

                            </div>
                            <div style="font-size: 10px;">
                                <i style="color: #55a532" class="el-icon-s-order"></i>转化率：{{scope.row.convertRate}}

                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

<!--        </el-drawer>-->


    </div>
</template>

<script>
    export default {
        name: "materialDetail",
        data() {
            return {
                resourceUrl: process.env.VUE_APP_API_PATH,
                checkBackVisible: false,
                tableList: [],
                exLoading: true,
                isCheck: 0,
                oldPushTextPre: '',
                materialOption: [],
                channelList: [],
                treeData: [],
                channelItem: [],
                priorityLevelList: [{id: 1, name: '高'}, {id: 2, name: '中'}, {id: 3, name: '低'}],
                pushTypeList: [{id: 1, name: '手动推送'}, {id: 2, name: '自动推送'}],
                form: {
                    id: '',
                    materialCode: '',
                    materialName: '',
                    materialType: 'text',
                    status: 1,
                    parentId: '',
                    materialClass: '',
                    pushText: '',
                    pushTextPre: '',
                    keyword: '',
                    url: '',
                    chanId: '',
                    seq: 0,//排序
                    pushType: 1,
                    groupId: '',//加入目录
                    isTransfer: 0,
                    channelState: '',
                    priorityLevel: 3,
                    recordLog: 1,
                    isWorkTransfer: 1,
                    checkStatus: 0,
                    title: '',
                    picUrl: '',
                    materialDesc: '',
                    appid: '',
                    picMediaId: '',
                    path: '',
                    checkContent: ''
                },
                blurIndex: null,//记录光标位置
                blurIndex1: null,
                tabsTemplateList: [
                    {
                        name: '昵称参数',
                        template: '${routeCustomer.nick_name}',
                        type: 'success'
                    }, {
                        name: '手机号参数',
                        template: '${routeCustomer.phone}',
                        type: 'success'
                    }, {
                        name: '用户等级参数',
                        template: '${routeCustomer.user_level}',
                        type: 'success'
                    }, {
                        name: '插入小程序链接参数',
                        template: '<a data-miniprogram-appid="${weixinMini.appid}" data-miniprogram-path="小程序链接" >显示文本</a>',
                        type: 'success'
                    }, {
                        name: '插入H5链接参数',
                        template: '<a href="链接地址 支持小程序链接和H5链接">显示文本</a>',
                        type: 'success'
                    }, {
                        name: '寄售订单顺序号',
                        template: '${order.douyinOrderNum}',
                        type: 'success'
                    }, {
                        name: '预付卡批次名',
                        template: '${prepayCard.title}',
                        type: 'success'
                    }, {
                        name: '子单号',
                        template: '${routeCustomer.order_no}',
                        type: 'success'
                    }
                ],
                fileList: []
            }
        },
        components: {},
        mounted(){
            this.initMaterialOption()
            this.initGroupList()
            this.initChannelList()
        },
        watch: {},
        methods: {
            getSingle(id, isCheck){
                this.isCheck = isCheck;
                if (id == null) {
                    this.showDetail();
                } else {
                    this.exLoading = true
                    this.$apiGet('lechun-cms/scrmMaterial/getMaterialWithType', {id: id}).then(res => {
                        this.exLoading = false
                        this.oldPushTextPre = res.pushTextPre
                        if (res.mediaType != null && res.materialType != 'text') {
                            res.materialType = res.mediaType
                        }
                        this.showDetail(res);
                        this.initLogList(id);
                    })
                }
            },
            //初始化目录列表
            initGroupList(){
                this.$apiGet('lechun-cms/sceneRouteGroup/getGroupList', {
                    currentPage: 1,
                    pageSize: 10000,
                    sceneRoute: this.sceneRoute
                }).then(res => {
                    console.log(res)
                    this.treeData = res.list
                })
            },
            initLogList(id){
                this.$apiGet('lechun-cms/scrmMaterial/getMaterialLogList', {materialId: id}).then(res => {
                    console.log(res)
                    this.tableList = res
                })
            },
            initChannelList(){
                this.$apiGet('lechun-bi/channelDict/getChannelDictList', {}).then(res => {
                    console.log(res)
                    this.channelList = res
                })
            },
            // 获取光标所在位置的index
            blurEvent(e) {
                this.blurIndex = e.srcElement.selectionStart;
                this.urlChange();
                console.log(e)
                console.log(e.srcElement)
                console.log(e.srcElement.selectionStart) //光标所在的位置
            },
            // 获取光标所在位置的index
            blurEvent1(e) {
                this.blurIndex1 = e.srcElement.selectionStart;
                this.urlChange();
                console.log(e)
                console.log(e.srcElement)
                console.log(e.srcElement.selectionStart) //光标所在的位置
            },
            // 插入内容的方法
            insertContentHandle(text, type) {
                let index = type == 0 ? this.blurIndex : this.blurIndex1
                let str = type == 0 ? this.form.pushTextPre : this.form.keyword
                if (text.indexOf("${weixinMini.appid}") >= 0) {
                    this.$apiGet('lechun-cms/weiXinBaseManage/getWeiXinBase', {"weixinBaseId": 10}).then(res => {
                        var appid = res.weixinBase.appid;
                        text = text.replace("${weixinMini.appid}", appid)
                        if (type == 0) {
                            this.form.pushTextPre = str.slice(0, index) + text + str.slice(index);
                        } else {
                            this.form.keyword = str.slice(0, index) + text + str.slice(index);
                        }

                    })
                } else {
                    if (type == 0) {
                        this.form.pushTextPre = str.slice(0, index) + text + str.slice(index);
                    } else {
                        this.form.keyword = str.slice(0, index) + text + str.slice(index);
                    }
                }
            },
            showDetail(row){
                if (row) {
                    this.form = row;
                    this.form.checkStatus = this.isCheck;
                    if (this.form.channelState != null) {
                        this.channelItem = this.form.channelState.split(',')
                    }
                } else {
                    this.form = {
                        id: '',
                        materialCode: '',
                        materialName: '',
                        materialType: '',
                        status: 1,
                        parentId: '',
                        materialClass: '',
                        pushText: '',
                        pushTextPre: '',
                        keyword: '',
                        pushType: 1,
                        url: '',
                        chanId: '',
                        channelState: '',
                        priorityLevel: 3,
                        title: '',
                        picUrl: '',
                        materialDesc: '',
                        appid: '',
                        picMediaId: '',
                        path: '',
                        checkStatus: 0
                    }
                    this.channelItem = []
                }
                this.dialogVisible = true
            },
            saveMaterial(recordLog, checkStatus){
                //console.log(recordLog);
                if (this.form.materialCode == '') {
                    this.$message({
                        type: 'error',
                        message: '请填写素材编码'
                    });
                    return;
                }
                if (this.form.materialType == '') {
                    this.$message({
                        type: 'error',
                        message: '请选择素材类型'
                    });
                    return;
                }
                if (this.form.materialClass == '') {
                    this.$message({
                        type: 'error',
                        message: '请选择素材分类'
                    });
                    return;
                }
                var fun = '';
                if (this.form.materialType == 'text') {
                    fun = 'saveTextMaterial';
                    if (this.form.pushTextPre == '') {
                        this.$message({
                            type: 'error',
                            message: '待发布文案必填'
                        });
                        return;
                    }
                } else if (this.form.materialType == 'image' || this.form.materialType == 'video' || this.form.materialType == 'voice' || this.form.materialType == 'file') {
                    fun = 'saveMediaMaterial';
                    if (this.form.materailOuterId == '' || this.form.ossUrl == '' || this.form.createTime == '' || this.form.disableTime == '') {
                        this.$message({
                            type: 'error',
                            message: '请先上传文件或等待文件上传成功'
                        });
                        return;
                    }
                } else if (this.form.materialType == 'miniprogram' || this.form.materialType == 'link') {
                    fun = 'saveLinkMaterial';
                    if (this.form.materailOuterId == '' || this.form.ossUrl == '' || this.form.createTime == '' || this.form.disableTime == '') {
                        this.$message({
                            type: 'error',
                            message: '请先上传文件或等待文件上传成功'
                        });
                        return;
                    }
                } else {
                    this.$message({
                        type: 'error',
                        message: '目前只支持文本、图片、视频、语音、文件程序 图文、小程序类型的素材保存'
                    });
                    return;
                }
                if (this.channelItem.length > 0) {
                    this.form.channelState = this.channelItem.join(",")
                }
                if (this.isCheck == 1) {
                    if (checkStatus == 2) {//审核不通过
                        this.form.recordLog = recordLog;
                        this.form.checkStatus = 2;
                        this.$apiPost('lechun-cms/scrmMaterial/' + fun, this.form).then(res => {
                            console.log(res)
                            this.$message({
                                message: '设置成功',
                                type: 'success'
                            });
                            this.checkBackVisible = false
                            this.$emit('initList');
                        })
                    } else {
                        if (fun == 'saveTextMaterial') {
                            this.$confirm('审核通过[待发布文案]将覆盖[已发布文案]，是否继续？', '提示', {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                type: 'warning'
                            }).then(() => {
                                this.form.recordLog = recordLog;

                                this.$apiPost('lechun-cms/scrmMaterial/' + fun, this.form).then(res => {
                                    console.log(res)
                                    this.$message({
                                        message: '保存成功',
                                        type: 'success'
                                    });
                                    this.$emit('initList');
                                })
                            })
                        } else if (fun == 'saveMediaMaterial') {
                            this.form.recordLog = recordLog;
                            this.$apiPost('lechun-cms/scrmMaterial/' + fun, this.form).then(res => {
                                console.log(res)
                                this.$message({
                                    message: '保存成功',
                                    type: 'success'
                                });
                                this.$emit('initList');
                            })
                        } else if (fun == 'saveLinkMaterial') {
                            this.form.recordLog = recordLog;
                            this.$apiPost('lechun-cms/scrmMaterial/' + fun, this.form).then(res => {
                                console.log(res)
                                this.$message({
                                    message: '保存成功',
                                    type: 'success'
                                });
                                this.$emit('initList');
                            })
                        }
                    }
                }
                if (this.isCheck == 0) {
                    //console.log(this.oldPushTextPre);
                    //console.log(this.form.pushTextPre);
                    if ((this.oldPushTextPre != '' && this.oldPushTextPre != this.form.pushTextPre) || this.form.status == 3) {
                        this.$confirm('如果修改了[待发布文案]系统将提交审核，审核通过后才能生效，是否继续？', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            this.form.recordLog = recordLog;
                            this.$apiPost('lechun-cms/scrmMaterial/' + fun, this.form).then(res => {
                                console.log(res)
                                this.$message({
                                    message: '保存成功',
                                    type: 'success'
                                });
                                this.$emit('initList');
                            })
                        })
                    } else {
                        this.$apiPost('lechun-cms/scrmMaterial/' + fun, this.form).then(res => {
                            console.log(res)
                            this.$message({
                                message: '保存成功',
                                type: 'success'
                            });
                            this.$emit('initList');
                        })
                    }
                }
            },
            initMaterialOption(){
                this.$apiGet('lechun-cms/scrmMaterial/getOptionMaterialList', {'materialClass': 1}).then(res => {
                    this.materialOption = res;
                })
            },
            urlChange(){
                this.$apiGet('lechun-cms/scrmMaterial/getHrefLongUrlChanId', {pushText: encodeURIComponent(this.form.pushTextPre)}).then(res => {
                    this.form.chanId = res;
                });
            },
            uploadSuccess(response, file, fileList){
                console.log("uploadMedia-response", response);
                console.log("uploadMedia-file", file);
                console.log("uploadMedia-fileList", fileList);
                if (response.status == 200 && response.value != null) {
                    this.form.materailOuterId = response.value.materailOuterId;
                    this.form.ossUrl = response.value.ossUrl;
                    this.form.createTime = response.value.createTime;
                    this.form.disableTime = response.value.disableTime;
                    if (this.form.link == 'link') {
                        this.form.picUrl = response.value.ossUrl;
                    }
                    if (this.form.link == 'miniprogram') {
                        this.form.picUrl = response.value.ossUrl;
                    }
                }
                console.log("this.form", this.form);
                this.$message({type: 'success', message: '文件上传成功!'});
            }
        }
    }
</script>

<style lang="scss" rel="stylesheet/scss">


    .example-dialog-wrap {
        .el-card__header {
            border-bottom: 1px solid #d7d7d7 !important;
        }
        .chat-item {
            display: flex;
            margin-bottom: 20px;
            font-size: 12px;
        }

        .chat-item-reverse {
            flex-direction: row-reverse;
        }
        .chat-img {
            width: 28px;
            display: block;
            margin-right: 10px;
        }
        .chat-img img {
            width: 28px !important;
        }
        .chat-item-reverse .chat-img {
            margin-left: 10px;
            margin-right: 0;
        }
        .chat-content {
            background: #f5f5f5;
            padding: 10px;
            border-radius: 5px;
            display: inline-block;
            white-space: pre-wrap;
            max-width: 80%;
        }
        .chat-item-reverse .chat-content {
            background: #a6e860;
        }
        .chat-miniprogram {
            border: 1px solid #dddddd;
            border-radius: 5px;
            padding: 10px;
            width: 200px;
        }
        .head_pic {
            border-radius: 30px;
            width: 132px;
            height: 132px;
        }
        .copy-text {
            white-space: pre-line;
            margin: 0;
            line-height: 18px;
            background: #f2f2f2;
            border: 1px solid #e8e8e8;
            border-radius: 5px;
            padding: 6px;
            max-height: 100px;
            overflow-y: scroll;
            scrollbar-width: none;
        }
    }


</style>

